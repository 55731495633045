import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { AdvantagesSection } from "../components/Landing/AdvantagesSection/AdvantagesSection";
import { HeroSection } from "../components/Landing/HeroSection/HeroSection";
import { HowItWorksSection } from "../components/Landing/HowItWorksSection/HowItWorksSection";
import { OfferSection } from "../components/Landing/OfferSection/OfferSection";
import { WhereToShare } from "../components/Landing/WhereToShare/WhereToShare";
import { ILP } from "../types";

export interface IndexPageProps { }

const IndexPage: React.FunctionComponent<IndexPageProps> = () => {
  const data: {
    allDataJson: {
      nodes: Array<{
        defaultLp: Omit<ILP, 'industry'>
      }>
    }
  } = useStaticQuery(graphql`
    query {
  allDataJson {
    nodes {
      defaultLp {
        heroSection {
					h1
					catchPhrases
					info1
					info2
				}
        advantagesSection {
          title
          text
          advantages {
            title
            text
            icon
          }
        }
        whereToShareSection {
          ways {
            title
            text
            icon
          }
        }
        offerSection {
          advantages
          prices {
            user
            sms
          }
        }
        socials {
          youtube
          facebook
          linkedIn
        }
      }
    }
  }
}
  `);

  const lp = data.allDataJson.nodes[0].defaultLp;


  return (
    <>
      <Helmet>
        <title>Wizyter.pl</title>
      </Helmet>
      <div>
        <Header withLoginButton />
        <HeroSection sectionData={lp.heroSection} />
        <HowItWorksSection />
        <AdvantagesSection sectionData={lp.advantagesSection} />
        <WhereToShare sectionData={lp.whereToShareSection} />
        <OfferSection sectionData={lp.offerSection} />
        <Footer socials={lp.socials} />
      </div>
    </>
  )
};

export default IndexPage;
